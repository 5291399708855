import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SwiperOptions } from 'swiper/types';

/**
 * This is a directive to be used with swiperjs`.
 * It's purpose is to allow easy configuration before the swiperjs web component `swiper-container` is initialized.
 *
 * Once configuration object is assigned to the swiper element, its own `initialize()` method is called.
 * See: https://swiperjs.com/element
 *
 * To use it, simply:
 * 1) Add `clSwiper` to your `<swiper-container>` *
 * 2) Pass your configuration as [config]
 *
 */

@Directive({ standalone: true, selector: '[appSwiper]' })
export class SwiperDirective implements AfterViewInit, OnChanges {
  @Input() config?: SwiperOptions;

  constructor(private el: ElementRef<HTMLElement & { initialize: () => void }>) {}

  // If the config changes, it allow swiper to apply the change directly
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      Object.assign(this.el.nativeElement, this.config);
    }
  }

  ngAfterViewInit(): void {
    if (this.el.nativeElement.tagName.toLowerCase() === 'swiper-container') {
      Object.assign(this.el.nativeElement, this.config);
      this.el.nativeElement.initialize();
      this.el.nativeElement.setAttribute('init', 'true');
    } else {
      console.warn('Invalid component used for `clSwiper` directive. Make sure to attach the directive to a `swiper-container` web component only');

      return;
    }
  }
}

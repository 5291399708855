import { SwiperOptions } from 'swiper/types';

export enum ReaderPageParams {
  UUID = 'uuid',
  CHAPTER = 'chapter',
  PAGE = 'page',
}

export const SWIPER_DEFAULT_CONFIG: SwiperOptions = {
  speed: 300,
};

export const SWIPER_PROFILE_SELECT_AVATAR_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 6,
  spaceBetween: 15,
  centerInsufficientSlides: true,
  autoHeight: true,
};

export const SWIPER_PROFILE_AVATAR_DESKTOP_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 'auto',
  spaceBetween: 15,
};

export const SWIPER_PROFILE_AVATAR_MOBILE_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 3.5,
  spaceBetween: 15,
};

export const SWIPER_ONBOARDING_CONFIG: SwiperOptions = {
  speed: 300,
  pagination: true,
};

export const SWIPER_ACTIVITY_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 1.1,
};

export const SWIPER_BOOK_DETAILS_SHELF_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 'auto',
  spaceBetween: 10,
};

export const SWIPER_SHELF_CONFIG: SwiperOptions = {
  speed: 300,
  slidesPerView: 6,
  spaceBetween: 5,
};

export const SWIPER_SHELF_CONFIG_MOBILE: SwiperOptions = {
  speed: 300,
  slidesPerView: 3,
  spaceBetween: 10,
};

export const SWIPER_SHELF_CONFIG_TABLET: SwiperOptions = {
  speed: 300,
  slidesPerView: 4,
  spaceBetween: 10,
};
